@import "Variables.css";

.compact-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    grid-gap: 10px;
}

.compact-boxes > div {
    padding: 10px 12px 10px 20px;
    align-content: start;
    display: grid;
    grid-auto-flow: row;
    /* border: 1px solid rgb(189, 189, 189); */
    background: var(--very-light-warm-color);
    border-radius: 10px;
}

.compact-boxes p {
    font-family: var(--body-font);
    font-weight: 500;
}

.term-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
}

.term-boxes > div {
    padding: 20px;
    align-content: start;
    display: grid;
    grid-auto-flow: row;
    /* border: 1px solid rgb(189, 189, 189); */
    background: var(--very-light-warm-color);
    border-radius: 10px;
}

.overview-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
    text-align: center;
}

.overview-boxes > div a.toplink {
    text-decoration: none;
    font-weight: 500;
}

.overview-boxes > div {
    padding: 10px 15px;
    align-content: start;
    justify-content: center;
    display: grid;
    grid-auto-flow: row;
    border-radius: 10px;
    color: var(--very-dark-color);
}

.overview-boxes > div {
    background-color: var(--light-tertiary-color);
}

.overview-boxes p {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.overview-boxes > div p:first-child {
    font-size: 36px;
    font-weight: 500;
}

.overview-boxes > div:first-child {
    background: #FFE9E1;
}
.overview-boxes > div:first-child a.toplink {
    color: #a0593f;
}

.overview-boxes > div:nth-child(2){
    background: #EAEFE8;
}
.overview-boxes > div:nth-child(2) a.toplink {
    color: #487635;
}

.overview-boxes > div:nth-child(3){
    background: #f9e4f7;
}
.overview-boxes > div:nth-child(3) a.toplink {
    color: #8e4287;
}

.overview-boxes > div:nth-child(5){
    background: #f8f1ce;
}
.overview-boxes > div:nth-child(5) a.toplink {
    color: dimgray;
}

.studenttable {
    width: 100%;
}

.studenttable tr:first-child {
    border-bottom: 1px solid var(--dark-color);
}

.studenttable td, th {
    padding: 0 10px;
}

.studenttable tr > td:last-child div {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.studenttable td.pic {
    width: 38px;
}

.student-mini-tile-wrapper {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit,minmax(170px,1fr));
    grid-gap: 20px;
}

.student-mini-tile {
    display: grid;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    /* background: white; */
    background: var(--very-light-warm-color);
    /* box-shadow: var(--shadow-1); */
    border-radius: var(--border-1);
}

.student-mini-tile-header {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    justify-items: end;
}

.student-mini-tile-header :nth-child(2) {
    justify-self: end;
}

.student-mini-tile h3 {
    color: var(--medium-dark-warm-color);
}

.student-mini-tile p {
    font-weight: 500;
    margin-bottom: 1px;
}

.student-mini-row-wrapper {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
    grid-gap: 20px;
}

.student-mini-row {
    position: relative;
    /* display: grid; */
    justify-content: center;
    align-items: start;
    padding: 20px;
    border: 1px solid #c1c4d0;
    box-shadow: 0px 0px 10px 0px #1628361e;
    border-radius: var(--border-1);
    transition: background-color 0.2s ease-in;
    text-align: center;
}

.student-mini-row h3 {
    color: var(--medium-dark-warm-color);
    padding-top: 10px;
    text-align: center;
}

.student-mini-row span {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 3px;
    text-align: center;
}

.student-mini-row small {
    font-weight: 500;
}

.student-mini-row:hover {
    cursor: pointer;
    background-color: var(--light-tertiary-color); /* rgb(206, 241, 255); */
    border: 1px solid var(--nav-color);
}

.student-mini-row:hover h3, .row-selected h3 {
    color: var(--nav-color);
}

.row-selected {
    border: 1px solid var(--nav-color);
    background-color: rgb(230, 249, 251);
}

.student-mini-row .badgebox {
    margin-top: -20px;
    /* position: absolute;
    top: 123px; */
    /* box-shadow: 0 -1px 1px 1px #1628361b; */
}


/* .student-mini-row img {
    box-shadow: 0px 0px 10px 0px #16283613;
} */

.student-mini-row .actions {
    position: absolute;
    top: 5px;
    right: 3px;
    justify-self: end;
}

.student-mini-row .check {
    position: absolute;
    top: 5px;
    left: 15px;
    justify-self: start;
    font-size: 20px;
    color: var(--nav-color);
}

.student-mini-row .actions .btn {
    border: 0;
    color: var(--nav-color);
}

.student-tile-wrapper {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
    grid-gap: 30px;
}

.student-tile {
    padding: 30px;
    background: white;
    box-shadow: var(--shadow-1);
    border-radius: var(--border-1);
}

.student-tile-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--lighter-dark-color);
}

.student-tile-header :nth-child(2) {
    justify-self: end;
}

.student-tile h2 {
    color: var(--medium-dark-warm-color);
}

.student-tile-body {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 150px auto auto;
    gap: 20px;
}

.student-tile-body small {
    color: var(--dark-color);
    font-weight: normal;
}

.student-tile-body p {
    font-weight: 500;
    margin-bottom: 1px;
}

.student-page-body {
    display: grid;
    gap: 20px;
}

.student-page-body small {
    color: var(--dark-color);
    font-weight: normal;
}

.student-page-body p {
    font-weight: 500;
    margin-bottom: 1px;
}

.student-split-view {
    display: grid;
    grid-template-columns: 320px 1fr;
    gap: 30px;
    height: 100%;
}

/* STUDENT CONTACTS TABLE */
.student-contact-table td {
    padding: 15px 0;
    text-align: left;
}

.student-contact-table th {
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid #b6b6c3;
    font-weight: 600;
    color: var(--dark-color);
    font-size: 14px;
    padding: 12px 30px 12px 0;
    font-family: var(--header-font);
    letter-spacing: 1px;
}

.student-contact-table td {
    border-bottom: 1px solid #b6b6c3;
}

/* STUDENT TABLE */
.attendance-header-cell {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--dark-color);
    padding-bottom: 5px !important;
    padding-top: 0 !important;
    font-size: 14px;
    font-family: var(--header-font);
    letter-spacing: 1px;
}

.student-attendance-wrapper > div {
    padding: 12px 30px 12px 0;
    height: 100%;
    min-height: 35px;
    display: grid;
    justify-items: start;
    align-items: center;
    border-bottom: 1px solid #b6b6c3;
    cursor: pointer;
}

.student-attendance-wrapper > div > * {
    width: 100%;
}

.student-attendance-wrapper > div.full-width {
    grid-column: 1/-1;
}

.student-attendance-wrapper {
    display: grid;
    grid-template-columns: auto max-content auto auto auto auto auto;
    /* grid-template-rows: auto; */
    align-items: center;
    grid-row-gap: 1px;
    /* overflow: scroll; commented out b/c autocomplete suggestions list gets clipped */
    hyphens: auto;
    overflow: scroll;
}

.student-attendance-cta {
    padding-right: 0 !important;
}

.student-attendance-pic {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 38px auto;
    gap: 10px;
}


.attendance-status {
    grid-auto-flow: column;
    gap: 0;
}

.attendance-status :nth-child(2) {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: var(--dark-color);
}

.attendance-status span.circle {
    font-size: 38px;
    line-height: 38px;
}

.attendance-status span.absent {
    color: var(--alert-color);
}

.attendance-status span.pickedup {
    color: var(--tertiary-color);
    /* light-warm-color */
}

.attendance-status span.present {
    color: var(--secondary-color);
}

.attendance-modal-date {
    display: block;
    font-size: 14px;
    color: var(--very-dark-primary-color);
    font-weight: 500;
    padding-bottom: 5px;
}

.time-row-split {
    display: grid;
    grid-template-columns: 1fr auto min-content auto auto;
    grid-auto-flow: column;
    grid-gap: 10px;
}

.time-colon {
    padding-top: 7px;
    font-size: 15px;
    font-weight: 600;
}

/* SCHOOL TILES */

.school-tile-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
}

.school-tile {
    padding: 30px;
    background: white;
    box-shadow: var(--shadow-1);
    border-radius: var(--border-1);
    margin-bottom: 30px;
}

.school-tile:last-of-type {
    margin-bottom: 0;
}

.school-tile-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--lighter-dark-color);
}

.school-tile-header :nth-child(2) {
    justify-self: end;
}

.school-tile-body {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 120px 1fr 1fr;
    gap: 30px;
}

.school-tile-body small {
    color: var(--dark-color);
    font-weight: normal;
}

.school-tile-body p {
    font-weight: 500;
    margin-bottom: 1px;
}

/* CONTACT TILES */

.contact-tile-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.contact-tile {
    padding: 30px;
    background: white;
    box-shadow: var(--shadow-1);
    border-radius: var(--border-1);
}

.contact-tile:last-of-type {
    margin-bottom: 0;
}

.contact-tile-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--lighter-dark-color);
}

.contact-tile-header :nth-child(2) {
    justify-self: end;
}

.contact-tile-body {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 120px 1fr 1fr;
    gap: 30px;
}

.contact-tile-body small {
    color: var(--dark-color);
    font-weight: normal;
}

.contact-tile-body p {
    font-weight: 500;
    margin-bottom: 1px;
}

.searchbar_and_filters {
    grid-auto-columns: 1fr auto;
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    align-items: start;
}

.fee-tile {
    padding: 30px;
    background: white;
    box-shadow: var(--shadow-1);
    border-radius: var(--border-1);
    margin-bottom: 30px;
}

.fee-tile:last-of-type {
    margin-bottom: 0;
}

.feeWrapper {
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
}

.feeItem {
    background-color: #f8f5f1;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto auto;
    gap: 0;
    align-items: center;
    padding: 10px 12px 10px 20px;
    border-radius: 10px;
}

.feeItem > div:first-child {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto 1fr auto;
    gap: 15px;
    align-items: center;
}

.feeItem .desc {
    border-top: 1px solid var(--light-warm-color);
    padding: 10px 0 0;
    margin-top: 11px;
    font-size: 13px;
    font-weight: 500;
    color: var(--dark-color);
}

.feeItem.disabled .desc {
    border-color: rgb(199, 204, 213);
}

.feeItem.disabled {
    background-color: #f2f4f6;
}

.feeItem.selectable:hover {
    background-color: #f5ece0;
    cursor: pointer;
}

.feeItem.selectable input:hover {
    cursor: pointer;
}

.feeItem.selected {
    background-color: var(--light-secondary-color);
}

.feeItem .cost {
    color: #1f6d22;
    font-size: 15px;
}

.feeTitle {
    font-weight: 600;
}

.feeTitle i {
    color: var(--warm-color);
    font-size: 15px;
}

.registrationHeader {
    border-bottom: 1px solid #d4d8e1;
    padding: 0 20px 20px 0;
    margin-bottom: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

.registrationHeader h2 {
    margin: 0;
    font-family: var(--body-font);
    font-weight: 600;
    font-size: 18px;
    /* color: var(--medium-dark-warm-color); */
    padding-right: 5px;
    text-transform: uppercase;
}

.stepNum {
    display: inline-flex;
    padding: 3px;
    background: rgb(255, 227, 192);
    color: rgb(113, 60, 35);
    width: 30px;
    height: 30px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    font-weight: 500;
    font-size: 18px;
}