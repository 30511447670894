/* Multi-select component */
@import "Variables.css";

.superadminuserwidget {
    display: grid;
    column-gap: 10px;
    justify-items: center;
}

.superadminuserwidget select {
    margin: 0;
}

.userwidget {
    display: grid;
    grid-template-columns: 38px auto;
    column-gap: 10px;
    border-radius: 25px;
    background: var(--primary-bg-color);
    /* height: 50px; */
    padding: 5px 20px 5px 5px;
}

.userwidget.light {
    background: rgba(255, 255, 255, 0.7);
}

.userwidget .widgettext {
    display: grid;
    grid-auto-flow: row;
    row-gap: 0;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
}

.superadminuserwidget > select, .widgettext > div > select {
    margin-bottom: 0;
    outline: none;
}

.userwidget .username {
    font-weight: 500;
}

.userwidget .userpic {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: darkgrey;
}
/* 
.userwidget .userpic > img {
    width: 100%;
    object-fit: cover;
} */

.userwidget select {
    display: block;
    background: none;
    outline: none;
    height: 25px;
    /* width: 100%; */
    padding: 0;
    border: 0;
    border-bottom: 1.5px solid var(--lighter-dark-color);
    border-radius: 0;
    font-family: inherit;
    font-size: 13px;
    font-weight: normal;
    color: var(--darker-color);
}