/* School Widget component */
@import "Variables.css";

.schoolswidget {
    display: grid;
    border-radius: 5px;
    background: var(--lightest-dark-color); /* ddd8cd */
    padding: 10px;
    margin-bottom: 20px;
    color: var(--darker-color);
}


.schoolswidget h3 {
    font-family: var(--body-font);
    color: var(--darker-color);
    font-weight: 600;
    letter-spacing: .5px;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    padding-bottom: 8px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--lighter-dark-color);
}

/* 
.superadminuserwidget > select, .widgettext > div > select {
    margin-bottom: 0;
    outline: none;
} */

.schoolswidget li.school a {
    padding: 2px 7.5px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    border-bottom: 0;
    color: var(--darker-color);
}

.schoolswidget li.school a.selected {
    background-color: rgba(255, 255, 255, 0.8);
    color: var(--darker-color);
}

.schoolswidget li.school a.selected:hover {
    background-color: var(--light-nav-color);
    color: var(--darker-color);
}