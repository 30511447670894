@import "Variables.css";
@import "ToggleSwitch.css";
@import "SegmentedButton.css";
@import "Multiselect.css";
@import "DatePicker.css";
@import "Superadmin.css";
@import "Admin.css";
@import "UserWidget.css";
@import "Tag.css";
@import "Autocomplete.css";
@import "SchoolsWidget.css";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: var(--body-font), sans-serif;
    font-size: 14px;
    background: var(--secondary-bg-color);
    color: var(--font-color);
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

a {
    color: var(--dark-tertiary-color);
    text-decoration: none;
    font-weight: normal;
}

h1,
h2, h3 {
    hyphens: auto;
    font-family: var(--header-font), serif;
    letter-spacing: 0 !important;
}

h3 {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-color);
}

h1 {
    font-size: 21px;
    /* margin-bottom: 5px; */
}

h1.headline {
    font-family: var(--header-font), serif;
    color: var(--darkest-color);
    font-size: 60px;
    line-height: 68px;
    margin-bottom: 0.15rem;
    text-align: center;
    font-weight: normal;
    max-width: 720px;
}

h1 a {
    letter-spacing: 0;
}

h2 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: normal;
    color: var(--darker-color);
}

.labelwithcheckbox > label {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
}

.darkcolor {
    color: var(--dark-color);
}

.darkercolor {
    color: var(--darker-color);
}

.darkestcolor {
    color: var(--darkest-color);
}

sup {
    font-size: 12px;
    font-weight: 700;
}

small {
    font-size: 13px;
    display: block
}

.title {
    font-size: 28px;
    font-family: var(--header-font);
    color: var(--warm-color);
    font-weight: normal;
}

.warm {
    color: var(--medium-dark-warm-color); 
}

.rel {
    position: relative;
}

ul {
    list-style: none;
}

img {
    width: 100%;
    object-fit: cover;
}

hr {
    margin-bottom: 1rem;
    border: none;
    width: 100%;
    height: 1px;
    color: #DDDFE3; /* old IE */
    background-color: #DDDFE3; /* Modern Browsers */
}

blockquote {
    quotes: '\201C''\201D''\2018''\2019';
    border-left: 10px solid #ccc;
    padding: 0.5em 10px;
}

blockquote:before {
    color: var(--primary-bg-color);
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

p {
    margin-bottom: 0.5rem;
}

.aligncenter {
    display: grid;
    align-items: center;
}

.single-checkbox-wrapper {
    border: 1px solid black;
    border-radius: 20px;
    height: 30px;
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    align-items: center;
    justify-content: start;
    padding: 0 20px;
}

.bigwarmhug .single-checkbox-wrapper {
    background: var(--very-light-warm-color) !important;
    border: 0;
    height: 38px;
}

.single-checkbox-wrapper:hover, .single-checkbox-wrapper > label {
    cursor: pointer;
}

.single-checkbox-wrapper > input {
    cursor: pointer;
}

.input-wrapper {
    position: relative;
    /* margin: 10px; */
}

.bigwarmhug .input-wrapper {
    margin-top: 20px;
}

.input-wrapper input, .input-wrapper select {
    position: relative;
    background: none;
}

.bigwarmhug .lwfloat {
    background: none;
    top: -18px;
    left: 0;
    padding: 0;
}

.lwfloat {
    position: absolute;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    top: -9px;
    z-index: 1;
    left: 15px;
    background-color: var(--secondary-bg-color);
    padding: 0 5px;
  }

p:last-child {
    margin-bottom: 0;
}

.impactText {
    font-size: 20px;
    line-height: 18px;
    font-weight: 500;
    font-family: var(--body-font), sans-serif;
}

.centerVertically { 
    display: grid;
    gap: 0;
    align-items: center;
}

.heightfitcontent {
    height: fit-content !important;
}

.margin-sides-wrapper {
    margin-left: 30px;
    margin-right: 30px;
    display: grid;
    justify-content: center;
}

.gridcol {
    grid-auto-columns: auto;
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    align-items: center;
}

.gridrow {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    gap: 10px;
    align-items: start;
}

.gridcol2packed {
    grid-auto-columns: auto 1fr;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 10px;
}

.gridcolpacked {
    justify-content: start;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 10px;
}

/* Utilities */
.containerfull {
    margin: 0;
    flex: 1; /* so it takes up all middle space after header and before footer */
    flex-direction: column;
    display: flex;
}

.containermiddle {
    flex: 1; /* so it takes up all middle space in containerfull, which also is flex: 1 */
    background: white;
    box-shadow: var(--shadow-1);
    position: relative;
    border-radius: var(--border-1);
    padding: 30px;
    margin-top: 30px;
}

.containerinner {
    display: flex;
    flex-flow: column;
    flex: 1; /* so it takes up all middle space in containerfull, which also is flex: 1 */
}

.split-2-header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.split-2-header :nth-child(2) {
    justify-self: end;
}

.whiteshadowtile {
    padding: 30px;
    background: white;
    box-shadow: var(--shadow-1);
    border-radius: var(--border-1);
}

.containerinnersolid {
    padding: 30px;
    height: 100%;
    background: white;
    box-shadow: var(--shadow-1);
    border-radius: var(--border-1);

    display: flex;
    flex-flow: column;
    flex: 1; /* so it takes up all middle space in containerfull, which also is flex: 1 */
}

.containerforleftnavandbody {
    flex: 1; /* so it takes up all middle space after header and before footer */
    display: grid;
    grid-template-columns: var(--left-nav-width) 1fr;
    margin: 0px;
    padding: 0px;
}

.containerbodyonly {
    flex: 1; /* so it takes up all middle space after header and before footer */
    display: grid;
    grid-template-columns: 1fr;
    margin: 0px;
    padding: 0px;
}

.containerforleftnavandbody div.leftnav {
    width: var(--left-nav-width);
    background-color: var(--primary-bg-color);
    flex: 1;
    padding: 20px;
}

/*.containerforleftnavandbody*/ div.rightnav {
    display: flex;
    flex-flow: column;
    align-content: space-between;
}

.containerforleftnavandbody div.leftnav hr {
    color: var(--lighter-dark-color);
    background: var(--lighter-dark-color);
    margin: 20px 0;
}

div.leftnav ul {
    color: var(--lighter-dark-color);
    width: 100%;
    display: grid;
    row-gap: 5px;
}

div.leftnav li {
    display: grid;
    gap: 10px;
}

div.leftnav li a {
    padding: 7.5px 10px;
    margin: 0;
    font-size: 14px;
    color: var(--dark-nav-color);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 0;
}

div.leftnav li a i {
    padding-right: 2px;
    width: 24px;
}

div.leftnav li a.selected {
    font-weight: 500;
    color: var(--light-nav-color);
    background: var(--nav-color);
    border-radius: 5px;
}

div.leftnav li a.selected:hover {
    color: white;
}

div.leftnav a:hover {
    color: var(--nav-color);
}

div.leftnav .navsectiontitle {
    text-transform: uppercase;
    color: var(--darker-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 8px;
    letter-spacing: .5px;
}

/* Landing Page */
.landing {
    padding: 20px 30px 30px;
    display: grid;
    grid-template-rows: auto;
    justify-items: center;
    grid-gap: 30px;
}

.landingbg {
    flex: 1 1;
    background-image: url('../img/bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 500px;
    background-attachment: fixed;
    min-height: 500px;
}

.landing h3 {
    max-width: 1000px;
    text-align: center;
    font: var(--body-font), sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.landingtile {
    grid-auto-flow: column;
    column-gap: 15px;
}

.landingtile h2 {
    color: var(--dark-warm-color);
}

.landingtitle p {
    font-size: 16px;
    color: var(--darker-color);
}

.landingtilebottom {
    margin-top: 20px;
    display: grid;
    grid-auto-flow: column;
    justify-content: left;
    align-content: center;
    align-items: center;
    column-gap: 8px;
}

.adminpicbg {
    display: grid;
    justify-content: center;
    align-items: center;
    background: var(--very-light-warm-color);
    width: 300px;
    border-radius: 10px;
}

.center {
    text-align: center;
    display: grid;
    grid-auto-flow: column;
    column-gap: 5px;
    justify-content: center;
}

.centerbox {
    display: grid;
    justify-content: center;
}

.centertext {
    text-align: center;
}

.width500 {
    width: 500px;
}

.width700 {
    width: 700px;
}

.width90percent {
    width: calc(100vw * .9);
}

.width70percent {
    width: calc(100vw * .7);
}

#leftnav {
    width: 290px;
}

.footer {
    padding: 20px 30px;
    font-size: 0.85rem;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.footer a {
    color: unset;
    text-decoration: underline;
    font-weight: normal;
}

.footertopline {
    border-top: 1px solid var(--lighter-dark-color);
    margin: 30px 30px 0 30px;
    padding: 20px 0;
}

.footer div sup {
    font-size: 9px;
    font-weight: normal;
}

#logo-footer {
    width: 28px;
    height: 28px;
}

.footer a.logolink {
    text-decoration: none;
    height: 28px;
}

.footer .footerleft {
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    align-items: center;
    column-gap: 5px;
}

/* Text Styles*/

.text-center {
    text-align: center;
}

.text-primary {
    color: var(--primary-color);
}

.text-secondary {
    color: var(--secondary-color);
}

.text-warm {
    color: var(--warm-color);
}

.text-white {
    color: white;
}

.text-dark {
    color: var(--light-dark-color);
}

.strong {
    font-weight: bold;
}


.block {
    display: block;
}

#full-screen-button {
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}

.inlineblock {
    display: inline-block;
}

.btn, button {
    display: grid;
    grid-auto-flow: column;
    column-gap: 7px;
    align-items: center;
    width: fit-content;
    color: var(--darkest-color);
    background: #ffffff3f;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--body-font), sans-serif;
    line-height: 10px;
    height: 30px;
    border: 1.5px solid var(--darkest-color);
    border-radius: 15px;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
    outline: none;
    margin: 0;
    text-decoration: none;
    white-space: nowrap;
}

.btn:hover {
    opacity: 0.8;
}

.btn:disabled, .btn-disabled, .btn:disabled:hover, .btn-disabled:hover {
    background: var(--primary-bg-color);
    color: var(--lighter-dark-color);
    border-color: var(--lighter-dark-color);
    cursor: default;
    opacity: 1;
}

.link-small {
    font-size: 14px;
}

.btn-icon {
    display: inline-block;
    background: none;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}

.btn-large {
    font-size: 18px;
    padding: 10px 20px;
    line-height: normal;
    height: fit-content;
    column-gap: 11px;
    border-radius: 30px;
}

.btn-small {
    font-size: 12px;
    padding: 3px 6px;
    line-height: 12px;
    height: fit-content;
    column-gap: 4px;
}

.inlinegrid {
    display: inline-grid;
}

.alert-container {
    width: 100%;
    position: fixed;
    top: 5px;
    left: 0;
    z-index: 100;
    pointer-events: none;
    /* allow clicks to go through container to potential nav links below */
}

.alert {
    padding: 10px 20px;
    margin: 0 auto 20px auto;
    background: var(--darker-alert-color);
    color: #fff;
    border-radius: 6px;
    max-width: 600px;
    min-width: 300px;
    pointer-events: initial;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.alert span {
    justify-self: flex-start;
    align-self: center;
}

.alert .fas {
    /* fontawesome icon */
    font-size: 1.5rem;
    justify-self: flex-end;
    align-self: center;
}

.alert .fas:hover {
    cursor: pointer;
}

.btn-primary, .btn-secondary, .btn-alert {
    border: 0;
    padding: 8px 15px;
}

.btn-primary {
    border: 0;
    border-bottom: 1px solid var(--dark-primary-color);
}

.btn-secondary {
    border: 0;
    border-bottom: 1px solid var(--dark-secondary-color);
}

.btn-alert {
    border: 0;
    border-bottom: 1px solid var(--darker-alert-color);
}

.btn-alert-border {
    border-color: var(--darker-alert-color);
    color: var(--darker-alert-color);
    transition: width 0.2s ease-out;
    /* for clipping text so it doesn't show when animating width: */
    position: relative;
    overflow: hidden;
}

.btn-primary,
.bg-primary,
.alert-primary {
    background: var(--primary-color);
    color: var(--very-dark-primary-color);
}

.btn-secondary,
.bg-secondary,
.alert-secondary {
    background: var(--secondary-color);
    color: var(--dark-secondary-color);
}

.btn-alert,
.bg-alert,
.alert-danger {
    background: var(--very-light-alert-color);
    color: var(--darker-alert-color);
}

.danger-color {
    color: var(--darker-alert-color);
}

.btn-success,
.bg-success,
.alert-success {
    background: var(--secondary-color);
    color: var(--dark-secondary-color);
}

.alert-color {
    color: var(--darker-alert-color);
}

.inline-notification {
    padding: 10px 20px;
    margin: inherit auto;
    background: var(--light-secondary-color);
    color: var(--dark-secondary-color);
    border-radius: 6px;
    pointer-events: initial;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.notification-error {
    background: var(--very-light-alert-color);
    color: var(--darker-alert-color);
}

.notification-warm {
    background: var(--light-warm-color);
    color: var(--dark-warm-color);
}

.whitebg {
    background-color: white;
}

/* Used for profile pics */
.round-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.round-img-avg {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.round-img-reg {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.round-img-sml {
    border-radius: 50%;
    width: 38px;
    height: 38px;
    object-fit: cover;
}

.round-img-xsml {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.round-img-med {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.round-img-lrg {
    border-radius: 50%;
    width: 260px;
    height: 260px;
    object-fit: cover;
}

.no-border-radius {
    border-radius: 0;
}

.border-radius-5 {
    border-radius: 5px;
}

section {
    margin: 0;
}

section.mytable {
    display: table;
    width: 100%;
  }
  
section.mytable > * {
    display: table-row;
  }
  
  section.mytable .col {
    display: table-cell;
  }

/* Search component */
.search-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto min-content;
    margin-bottom: 20px;
}

.nogap {
    gap: 0;
}

input[type='text'].searchbar {
    border: 0;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background: none;
    height: 27px;
}

.searchbar-wrapper {
    display: grid;
    align-items: center;
    grid-gap: 10px;
    grid-template-columns: min-content auto min-content;
    width: 100%;
    height: 30px;
    padding: 0 10px 0 15px;
    font-size: 14px;
    border: 1.5px solid var(--darker-color);
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.5);
    font: inherit;
    font-weight: normal;
    color: var(--darker-color);
    outline: none;
}

.searchbar-wrapper :last-child {
    height: 27px;
    align-items: center;
    display: grid;
}

.search-icon {
    display: grid;
    align-items: center;
    height: 27px;
}

.searchbar {
    display: block;
    outline: none;
    background: #fff;
    opacity: 0.8;
    width: 100%;
    padding: 0.4rem 0.4rem 0.4rem 0;
    font-size: 1.2rem;
    font: inherit;
    border: none;
    color: var(--darker-color);
}

.searchbar-clear {
    align-self: center;
    justify-self: end;
}

/* Forms */

.form {
    display: grid;
    grid-auto-flow: row;
}

/* .form input:last-of-type {
    margin-bottom: 0;
} */

.form-header {
    display: block;
    font-size: 14px;
    color: var(--dark-color);
    font-weight: 500;
    padding-bottom: 5px;
}

.form-header2 {
    display: block;
    color: var(--very-dark-color);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

/* Used for small text under fields */
.form-text {
    display: block;
    color: var(--dark-color);
    font-weight: 500;
    font-size: 12px;
}

/* for captcha module on registration form*/
#reload_href {
    font-size: 13px !important;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='file'],
select,
textarea {
    display: block;
    /* background: #fff; */
    width: 100%;
    height: 30px;
    padding: 4px 15px;
    margin-bottom: 15px;
    font-size: 14px;
    border: 1.5px solid var(--darker-color);
    border-radius: 20px;
    font: inherit;
    font-weight: normal;
    color: var(--darker-color);
    outline: none;
}

textarea {
    height: auto !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    margin-bottom: 0 !important;
}

::placeholder,
input::placeholder, 
input::-webkit-input-placeholder, /* Edge */ 
input:-ms-input-placeholder /* Internet Explorer 10-11 */ { 
    color: var(--placeholder-warm-color);
}

.form.bigwarmhug input[type='text'],
.form.bigwarmhug input[type='email'],
.form.bigwarmhug input[type='password'],
.form.bigwarmhug input[type='date'],
.form.bigwarmhug input[type='file'],
.form.bigwarmhug select,
.form.bigwarmhug textarea, .warmwrapper {
    background: var(--very-light-warm-color) !important;
    border: 0;
    height: 38px;
}

.warmwrapper {
    border-radius: 19px;
    padding: 0 15px;
}

.borderwrapper {
    border: 1.5px solid var(--darker-color);
    border-radius: 30px;
    padding: 3px 12px;
    height: 30px;
}

.pr-6 {
    padding-right: 6px;
}

.form input[type='submit'] {
    font-size: 14px;
    height: 30px;
    line-height: 10px;
    font-weight: normal;
}

.row-split-evenly {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 30px;
}

.auto2split {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto 1fr;
}

.gap45 {
    grid-gap: 45px;
    gap: 45px;
}

.gap0 {
    grid-gap: 0;
    gap: 0;
}

.even2split, .even2split-spacebtwn {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.even2split-spacebtwn :nth-child(2) {
    justify-self: end;
}

.evennsplit {
    display: grid;
    grid-auto-flow: column;
}


.grid-gap-20 {
    grid-gap: 20px;
}

.grid-gap-30 {
    grid-gap: 30px;
}

.badge {
	padding: 2px 8px;
    font-family: var(--body-font);
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
	min-height: 23px;
	border-radius: 6px;
    width: fit-content;
}

.badge.border {
    border-width: 1px;
    border-style: solid;
}

.neutralbadge {
	color: var(--darker-tertiary-color);
	background: var(--light-tertiary-color);
}

.neutralbadge.border {
	border-color: var(--darker-tertiary-color);
}

.successbadge {
	color: var(--dark-secondary-color);
	background: var(--light-secondary-color);
}

.successbadge.border {
	border-color: #93b180;
}

.warningbadge {
	color: var(--very-dark-primary-color);
	background: var(--light-primary-color);
}

.warningbadge.border {
	border-color: #d0bb89;
}

.errorbadge {
	color: var(--darker-alert-color);
	background: var(--very-light-alert-color);
}

.errorbadge.border {
	border-color: #c6829b;
}

.darkbadge {
	color: var(--darker-color);
	/* background: var(--secondary-bg-color); */
    background: rgba(255, 255, 255, 0.132);
}

.darkbadge.border {
	border-color: #8495a1;
}

header {
    position: sticky;
    z-index: 10;
    height: fit-content;
    width: 100%;
    top: 0;
    transition: background-color 0.2s ease-out;
    transition: box-shadow 0.2s ease-out;
}

header.headerbg {
    background-color: #f9fafc52;
    backdrop-filter: blur(7px);
    box-shadow: var(--shadow-1);
    transition: background-color 0.4s ease-in;
    transition: box-shadow 0.4s ease-in;
}

#school-header-wrapper {
    background:linear-gradient(to right, var(--secondary-bg-color) 0%, #d7f2db 49%, var(--secondary-bg-color) 100%);
    padding: 2px 30px;
    margin: -30px 0 20px 0;
    /* border-bottom: 1px solid var(--lighter-dark-color); */
    display: grid;
    /* grid-template-columns: 38px 1fr; */
    gap: 10px;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 4px;

    /* position: sticky;
    top: 0;
    z-index: 14; */

    font-size: 13px;
    font-family: var(--body-font);
    text-transform: uppercase;
    font-weight: 600;
}

/* */
/* plan tile */

.plantiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
}

.plantile {
    display: grid;
    grid-auto-flow: row;
    align-items: left;
    padding: 15px;
    gap: 15px;
    background: var(--very-light-warm-color);
    border-bottom: 1px solid var(--placeholder-warm-color);
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
}

.plantile h1 {
    font-size: 18px;
    font-weight: 700;
    font-family: var(--body-font);
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}

.plantile .promobox {
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    padding: 5px 20px; 
}

.plantile .promobox, .plantile .featuretitle {
    font-size: 13px;
    font-weight: 500;
    height: fit-content;
    text-transform: uppercase;
}

.plantile .pricebox {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 9px;
    width: fit-content;
    margin: auto;
}

.plantile .pricebox h1 {
    font-size: 46px;
    line-height: 44px;
    font-weight: 600;
    font-family: var(--body-font), sans-serif;
    text-transform: none;
}

.plantile .pricebox .red {
    color: var(--darker-alert-color);
    font-size: 26px;
    opacity: 60%;
}

.plantile .pricebox .unit {
    color: var(--dark-color);
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
    text-transform: uppercase;
}

.plantile hr {
    margin-bottom: 0;
    border: none;
    width: 100%;
    color: var(--light-warm-color); /* old IE */
    background-color: var(--light-warm-color); /* Modern Browsers */
}

.plantile .features {
    display: grid;
    grid-auto-flow: row;
    justify-items: left;
    gap: 0;
}

.plantile .features p {
    margin-bottom: 7px;
}

/* admin stuff */

.admin-form-footer {
    display: grid;
    padding: 20px 0 20px;
    margin-bottom: -20px;
    margin-top: 10px;
    grid-template-columns: auto auto;
    position: sticky;
    bottom: 0px;
    background: #fff;
    border-top: 1px solid var(--lighter-dark-color);
}


/* Admin Navbar */

.adminnavbar {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-auto-flow: column;
    z-index: 5;
    height: -moz-fit-content;
    height: fit-content;
    background: white; /* #F9FAFC; */
    hyphens: auto;
    /* padding: 10px 0; */
    box-shadow: var(--shadow-1);
    /* box-shadow: 0px 0px 20px 0px #4e4e4e24; */
}

.adminnavbar div.left {
    padding: 10px 20px;
    /* border-right: 1px solid var(--lighter-dark-color); */
}

.adminnavbar div.middle {
    padding: 10px 30px;
}

.adminnavbar div.right {
    padding: 10px 30px 10px 0;
}

.adminnavbar > div {
    display: grid; 
    grid-auto-flow: column;
    align-items: center;
    width: fit-content;
    column-gap: 15px;
}


.namesection {
    border-right: 1px solid var(--lighter-dark-color);
    display: grid; 
    grid-auto-flow: column;
    grid-template-columns: 38px auto;
    align-items: center;
    column-gap: 5px;
    padding-right: 20px;
}

.namesection div:first-of-type {
    margin-right: 5px;
}

.namesection > img {
    width: 30px;
}

.logotitle {
    display: grid;
    grid-template-columns: auto auto;
    align-content: center;
    column-gap: 5px;
    font-family: 'Rajdhani', sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-decoration: none;
    color: var(--darkest-color);
    width: fit-content;
}

.adminnavbar a.title:hover {
    color: var(--dark-color);
}

.logo-nav {
    width: 34px;
    height: 34px;
}

.adminnavbar a.selected {
    font-weight: 700;
}

.adminnavbar ul {
    display: flex;
}

.adminnavbar a {
    color: var(--darkest-color);
}

.adminnavbar a:hover {
    color: var(--dark-color);
}

/* animations */
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.05, 1.05);
    }
    100% {
      transform: scale(1, 1);
    }
  }

/* Landing Header */

#landingheader {
    display: flex;
    justify-content: space-between;
    border: none;
    align-items: center;
    height: fit-content;
    hyphens: auto;
    padding: 20px 30px;
}

#landingheader a.title {
    text-transform: uppercase;
    text-decoration: none;
    display: grid;
    grid-template-columns: auto auto;
    align-content: center;
    column-gap: 5px;
    font-family: 'Rajdhani', sans-serif;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: var(--darkest-color);
}

#landingheader a.title:hover {
    color: var(--dark-color);
}

.logo-nav {
    width: 34px;
    height: 34px;
}

.landingheader-buttons {
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    column-gap: 15px;
}

/* modal */
.modalheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin: 0 5px;
    font-family: var(--header-font);
    font-size: 24px;
    border-bottom: 1px solid var(--primary-bg-color);
    border-top-left-radius: var(--border-1);
    border-top-right-radius: var(--border-1);
}

.modalheader div:first-of-type {
    min-width: 24px;
    min-height: 24px;
}

.modalfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin: 0 5px;
    border-top: 1px solid var(--primary-bg-color);
    border-bottom-left-radius: var(--border-1);
    border-bottom-right-radius: var(--border-1);
}

i.iconlink {
    cursor: pointer;
}

.modalheader a {
    color: var(--font-color);
}

.modalbody {
    padding: 20px 30px 30px 30px;
    border-bottom-left-radius: var(--border-1);
    border-bottom-right-radius: var(--border-1);
    height: fit-content;
    overflow: scroll;
    position: relative;
}

.modalbodyforoverlay {
    max-height: calc(100vh * 0.9 - 140px); /* accounts for header, footer, and space on top/bottom */
    min-height: 200px;
}

.modalwrapper {
    flex: 1; /* so it takes up all middle space in containerfull, which also is flex: 1 */
    margin: 0 auto;
    box-shadow: var(--shadow-1);
    border-radius: var(--border-1);
    background: white;
}

.modal-overlay-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(10, 10, 11, 0.6);
    z-index: 15;
}

.modal-overlay-main {
    position: fixed;
    max-height: calc(100vh * 0.9);
    z-index: 16;

    left:50%;
    /* top:50%;
    transform: translate(-50%,-50%); */
    top: 20px;
    transform: translate(-50%,0);
  }

  

/* tab bar */
.tabbar {
    display: flex;
    justify-content: space-between;
    border: none;
    align-items: center;
    height: fit-content;
    hyphens: auto;
    margin-bottom: 30px;
}

.tabbar ul {
    display: flex;
    border-bottom: 1.5px solid var(--lighter-dark-color);
    width: 100%;
}

.tabbar li {
    margin-right: 5px;
    padding: 8px 0 6px 0;
    font-size: 14px;
}

.tabbar li a {    
    padding: 7px 14px 7px 14px;
    margin: 0;
    color: var(--darker-color);
    font-weight: 500;
    text-decoration: none;
    /* border: 1px solid var(--gray-accent-color); */
    border-bottom: 0;
    border-top: 0;
}

.tabbar li a.selected {
    color: var(--light-nav-color);
    border-bottom: 1.5px solid var(--nav-color);
    border-top: 1.5px solid #1c2c3d;
    border-radius: 5px 5px 0 0;
    background: var(--nav-color);
}

.tabbar a:hover {
    color: var(--dark-nav-color);
}

.tabbar li a.selected:hover {
    color: white;
}

.verticaltabbar {
    display: flex;
    justify-content:flex-start;
    border: none;
    align-items: flex-start;
    padding: 10px 0 0;
    height: fit-content;
    hyphens: auto;
    font-size: 105%;
}

.verticaltabbar ul {
    display: flex;
    border-bottom: 1px solid var(--gray-accent-color);
    width: 100%;
}

.verticaltabbar li {
    margin-right: 36px;
    padding: 16px 0;
    /* background: var(--very-light-color); */
}

.verticaltabbar li a {
    padding: 8px 0;
    margin: 0;
    color: var(--medium-primary-color);
    font-weight: normal;
    /* border: 1px solid var(--gray-accent-color); */
    border-bottom: 0;
}

.verticaltabbar li a.selected {
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
    padding-bottom: 14px;
}

.verticaltabbar a:hover {
    color: var(--medium-primary-color);
}

.previewnotification {
    background: var(--darkest-color);
    color: rgb(237, 237, 237);
    display: flex;
    flex-direction: row;
    box-shadow: var(--shadow-1);
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 3px 30px;
    height: fit-content;
    z-index: 4;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    letter-spacing: 8px;
    text-transform: uppercase;
    /*linear-gradient(90deg, #e4fdff, #caecff); /* #e4fff6 #c4e2d6 */
}

.previewnotification div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 2;
}

/* to put input and upload button on same line */
.upload-actions {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;
}

.upload-box {
    display: flex;
    margin: 0 0 20px 0;
    flex-flow: column;
    align-items: center;
    border: 1px solid var(--lighter-dark-color);
    border-radius: 10px;
    padding: 20px;
}

.upload-box small {
    margin: 10px;
}

.upload-box input {
    margin: 0;
    height: 30px !important;
}

.shadow {
    box-shadow: 0px 10px 20px #4e4e4e1e;
}

/* PAGINATION */
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.paginationItem {
    background: none;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
}

.paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prev,
.next {
    background: #fff;
    border: none;
    padding: 10px;
    color: var(--primary-color);
    font-size: 2.3rem;
    font-weight: bold;
    /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.4); */
    margin: 0 10px;
    cursor: pointer;
}

.paginationItem.dots {
    border: none;
    pointer-events: none;
    color: var(--lighter-dark-color);
    padding: 10px 0;
    margin: 0;
}

.paginationItem.active {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
    pointer-events: none;
}

.prev.disabled,
.next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: var(--lighter-dark-color);
}

/* Padding */

.p {
    padding: 5px;
}
.p-1 {
    padding: 10px;
}
.pl-1 {
    padding-left: 10px;
}
.pr-1 {
    padding-left: 10px;
}
.p-2 {
    padding: 20px;
}
.p-3 {
    padding: 30px;
}
.py {
    padding: 5px 0;
}
.py-1 {
    padding: 10px 0;
}
.py-2 {
    padding: 20px 0;
}
.py-3 {
    padding: 30px 0;
}
.pb {
    padding: 0 0 5px 0;
}

.pb-0 {
 padding-bottom: 0;
}

.pb-1 {
    padding: 0 0 10px 0;
}
.pb-2 {
    padding: 0 0 20px 0;
}
.pb-3 {
    padding: 0 0 30px 0;
}
.pt-0 {
    padding-top: 0;
}
.p-0 {
    padding: 0;
}
.pt {
    padding: 0.5rem 0 0 0;
}
.pt-1 {
    padding-top: 10px;
}
.pt-2 {
    padding-top: 20px;
}
.pt-3 {
    padding-top: 30px;
}

/* Margin */

.ml {
    margin-left: 5px;
}

.ml-1 {
    margin-left: 10px;
}

.mr {
    margin-right: 0.4rem;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 10px;
}

.m {
    margin: 5px;
}
.m-1 {
    margin: 10px;
}
.mt-1n {
    margin-top: -10px;
}
.m-2 {
    margin: 20px;
}
.m-3 {
    margin: 30px;
}
.my {
    margin: 5px 0;
}
.my-1 {
    margin: 10px 0;
}
.my-2 {
    margin: 20px 0;
}
.my-3 {
    margin: 30px 0;
}

.mt {
    margin-top: 5px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}
.mt-3 {
    margin-top: 30px;
}

.mb {
    margin-bottom: 5px;
}

.m-0 {
    margin: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 10px;
}
.mb-1-5 {
    margin-bottom: 15px;
}
.mb-2 {
    margin-bottom: 20px;
}
.mb-3 {
    margin-bottom: 30px;
}

.displaynone {
    display: none;
}

/* @media screen and (max-width: 1400px){
    .admin-form-footer {
        border-radius: 5px;
      position: fixed;
      width: calc(100% - 340px);
      padding: 20px;
        bottom: 0;
      margin-bottom: 0;
      margin-left: -30px;
    }
    .containerinnersolid {
        position: relative;
    }
  } */

@media (max-width: 1100px) {
    .landingtile .row-split-evenly {
        grid-template-columns: none;
        grid-auto-flow: row;
        row-gap: 30px;
    }

    .adminpicbg {
        width: 100%;
        justify-self: center;
        object-fit: cover;
        background-size: cover;
    }

}

/* Mobile Styles */
@media (max-width: 700px) {
    .container {
        margin-top: 8rem;
    }

    .containerfull {
        margin: 0;
        /* min-height: unset; */
    }

    .hide-on-mobile {
        display: none;
    }

    .row-split-evenly, .plantiles {
        grid-template-columns: none;
        grid-auto-flow: row;
        row-gap: 30px;
    }

    .paginationItem {
        padding: 15px;
        height: auto;
        width: auto;
    }

}
