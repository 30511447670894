/* DatePickercomponent */
@import "Variables.css";


.datepicker {
    font-family: var(--body-font), Arial, sans-serif; /* 'Open Sans Condensed',  */
	min-width: 250px;
	margin: 0 auto;
	background: white;
	border-radius: 10px;
    /* border: 1px solid var(--lighter-dark-color); */
	user-select: none;
}

.datePickerOpener {
    display: grid;
    grid-auto-flow: column;
    column-gap: 7px;
    align-items: center;
    width: fit-content;
    color: var(--darkest-color);
    background: none;
    padding: 6.5px 15px;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--body-font), sans-serif;
    line-height: 10px;
    height: 30px;
    border: 1.5px solid var(--darkest-color);
    border-radius: 15px;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
    outline: none;
    margin: 0;
    text-decoration: none;
}

.datepicker-popover {
	border-radius: 10px;
    position: absolute;
    z-index: 1000;
    box-shadow: var(--shadow-1);
}

.datepicker-popover .datepicker {
    border: none;
}

.datepicker-popover .datepicker-month {
    background: var(--lighter-dark-color);
    gap: 15px;
    padding: 5px 20px;
}

.datepicker-popover .datepicker-calendar {
	padding: 10px;
}

.datepicker-month {
    display: grid;
    grid-template-columns: 1fr auto auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 500;
	font-size: 18px;
    justify-items: center;
    /* background: var(--lighter-dark-color); */
    border-bottom: 1px solid var(--lighter-dark-color);
    padding: 5px 0;
    gap: 20px;
}

.datepicker-month-title {
    justify-self: start;
}

.datepicker-month .datepicker-month-prev, .datepicker-month .datepicker-month-next {
    font-size: 18px;
	cursor: pointer;
}

.datepicker-calendar {
	width: 100%;
	text-align: center;
	font-size: 16px;
    font-weight: 300;
	padding-top: 10px;

    
}

.datepicker-calendar .th {
	font-weight: bold;
	text-transform: uppercase;
    text-align: center;

    flex: 30px 0 0;
}

.datepicker-calendar .td {
	padding: 6px;
}

.datepicker-calendar .td div {
    padding: 2px 6px;
}

.datepicker-calendar .active-day {
	cursor: pointer;
	border-radius: 16px;
}

.datepicker-calendar .active-day:hover {
	color: var(--nav-color);
	background: var(--light-nav-color);
	border-radius: 16px;
}

.datepicker-calendar .inactive-day {
	color: var(--lighter-dark-color);
}

.datepicker-calendar .inactive-day.selected {
	color: var(--lighter-dark-color);
	background: #83a3c43d;
	border-radius: 16px;
}


.datepicker-calendar .inactive-day.highlighted-date {   
    color: var(--lighter-dark-color);
	background: #83a4c421;
    border: 1px solid #b1cce6;
	border-radius: 16px;
}

.datepicker-calendar .active-day.highlighted-date {
    color: var(--nav-color);
	/* background: rgb(246, 242, 215); */
    border: 1px solid var(--medium-nav-color);
	border-radius: 16px;
}

.datepicker-calendar .selected-date {
	color: var(--light-nav-color);
	background: var(--medium-nav-color);
    font-weight: 500;
	border-radius: 16px;
}

.datepicker-calendar .selected-date.highlighted-date {
	color: white;
}

.datepicker-calendar .selected-date.highlighted-date:hover {
	color: var(--nav-color);
}

.datepicker-calendar .today {
    font-weight: 600;
	/* background: var(--light-warm-color); */
	/* color: var(--dark-warm-color); */
	border-radius: 16px;
}

.datepicker-calendar .today:hover {
	background: #577294;
    color: white;
}

.datepicker.datepicker-size-small {
    width: fit-content;
    margin: unset;
}

.datepicker.datepicker-size-small .datepicker-calendar {
    font-size: 15px;
}

.datepicker.datepicker-size-small .datepicker-calendar .td {
	padding: 2px 6px;
}

.datepicker.datepicker-size-medium {
    width: 500px;
}

.datepicker.datepicker-size-full {
    width: 100%;
    font-size: 20px;
}

.datepicker.datepicker-size-tall {
    width: 100%;
    font-size: 20px;
    /* border-bottom: 1px solid var(--lighter-dark-color); */
    /* padding-bottom: 10px; */
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    height: 100%;
    min-height: 340px;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.datepicker.datepicker-size-tall .datepicker-calendar {
    height: 100%; 
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.cal-wrapper {
    height: 100%;
}

.cal-wrapper.datepicker-popover {
    height: auto;
}

.datepicker.datepicker-size-tall .datepicker-calendar .td div {
    height: 100%;
    /* align-items: center; */

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

hr.calendar {
    color: var(--lighter-dark-color); /* old IE */
    background-color: var(--lighter-dark-color); /* Modern Browsers */
}

/* FLEX STUFF */

.week {
    flex: 30px 1 0;
}

/* Calendar rows */
.th, .week { display: flex; }
.th>span {
    padding: 0 2px 2px 0;
}
.week>div {
    padding: 20px 2px 2px;
    /* min-height: 50px; */
    position: relative; /* allow ::after psuedo element absolute positioning */
}
.th>span, .week>div {
    width: 100%;
    margin-right: 5px;
}

/* Weekends */
/* .th>span:first-child, .th>span:last-child, .week>div:first-child, .week>div:last-child {background:#f3f3f3} */
.th>span:last-child, .week>div:last-child {margin-right:0}

.calendar-split-view {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 30px;
    height: 100%;
}