
.item-tag {
    border: 1px solid var(--dark-color);
    color: var(--dark-color);
    border-radius: 12px;
    display: inline-block;
    /* white-space: nowrap; */
    margin: 2px 5px 2px 0;
    padding: 3px 8px;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: auto !important;
}

.item-tag i {
    padding-left: 5px;
}

.tag-error {
    color: var(--darker-alert-color);
    border: 1px solid var(--darker-alert-color);
}

.tag-warning {
    color: var(--dark-primary-color);
    border: 1px solid var(--dark-primary-color);
}

.tag-success {
    color: var(--dark-secondary-color);
    border: 1px solid var(--dark-secondary-color);
}

.tag-clickable:hover {
    cursor: pointer;
}

.tag-clickable a {
    color: inherit;
    font-weight: inherit;
    text-decoration: inherit;
}

.item-tag.tag-clickable.tag-default {
    /* color: var(--default-clickable-tag-color); */
    border: 1px solid var(--dark-color);
    background: var(--very-light-secondary-color);
}

.item-tag.tag-clickable.tag-success {
    background: var(--light-secondary-color);
}

.item-tag.tag-clickable.tag-error {
    background: var(--very-light-alert-color);
}

.item-tag.tag-clickable.tag-warning {
    background: var(--primary-color);
}

.tag-clickable:hover,
.item-tag.tag-clickable.tag-default:hover,
.item-tag.tag-clickable.tag-success:hover,
.item-tag.tag-clickable.tag-error:hover,
.item-tag.tag-clickable.tag-warning:hover {
    background: white;
}

.item-tag i.deletable:hover {
    cursor: pointer;
    color: var(--alert-color);
}