/* Multi-select component */
@import "Variables.css";

.multiselect-group {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    padding: 10px 0 0;
}

.multiselect-group div {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid var(--lighter-dark-color);
    border-radius: 5px;

    height: 30px;
    display: grid;
    grid-auto-flow: column;
    column-gap: 0;
    justify-content: left;
    align-content: center;

    font-size: 14px;
    line-height: 16px;
    padding: 7px;
}

.bigwarmhug .multiselect-group div {
    border: none;
}

.multiselect-group.solid > div {
    background: var(--very-light-warm-color);
    min-height: 38px;
    height: auto;
    border: 0;
}


.multiselect-group div:hover,
.multiselect-group label,
.multiselect-group input {
    cursor: pointer;
}

.multiselect-group input {
    margin-right: 10px;
}

.ms-checked, .multiselect-group div.solid.ms-checked {
    background: var(--light-secondary-color);
    border-color: var(--light-color);
}



.ms-unchecked {
    background: none;
}
