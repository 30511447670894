.superadmin-user-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.superadmin-user-box {
    padding: 20px;
    align-content: center;
    justify-content: center;
    display: grid;
    grid-auto-flow: row;
    border-radius: 10px;
    background: var(--very-light-warm-color);
}

.superadmin-user-box img {
    justify-self: center;
    margin-bottom: 1rem;
}

.superadmin-user-box p:first-of-type {
    justify-self: center;
    font-size: 1.3rem;
}

.superadminbadge {
	color: var(--darker-color);
	background: var(--light-tertiary-color);
}