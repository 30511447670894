/* Global Styles */
:root {
    --primary-color: #FFDD85;
    --light-primary-color: #FFF2D2;
    --dark-primary-color: #825F03;
    --very-dark-primary-color: #503900;

    --secondary-color: #9BC483;
    --light-secondary-color: #E2F1DE;
    --dark-secondary-color: #204609;

    --tertiary-color: #A6D7DE;
    --light-tertiary-color: #D9F5F9;
    --dark-tertiary-color: #428994;
    --darker-tertiary-color: #286068;

    --nav-color: #205a88;
    --medium-nav-color: #6e98c0;
    --light-nav-color: #f1f6fd;
    --dark-nav-color: #1a3044;

    --primary-bg-color: #e6e8f2; /* # e6e8f2 f1efeb */
    --secondary-bg-color: #fcfdff; /*rgb(252, 252, 249); #F3F5F9; f5f7fb FFFEFC*/

    --dark-warm-color: #463B31;
    --medium-dark-warm-color: #745a43;
    --warm-color: #927357;
    --light-warm-color: #E7D4C3;
    --very-light-warm-color: #F8F5F0;

    --lightest-dark-color: #cad2df;
    --lighter-dark-color: #A7BCCB;
    --dark-color: #5A6D7C;
    --darker-color: #2D3E4A;
    --darkest-color: #162836;

    --placeholder-cool-color: #7C8D99;
    --placeholder-warm-color: #938D83;
    --disabled-color: #93a1aa;

    --darkest-alert-color: #5E0021;
    --darker-alert-color: #84153D;
    --alert-color: #FF689E;
    --light-alert-color: #FF92B9;
    --very-light-alert-color: #FFCCDE;

    --twinkle-gradient: linear-gradient(-90deg, #EEF9DF, #CFEEE6, 80%);

    --font-color: var(--darker-color);
    
    --header-font: 'Calistoga';
    --body-font: 'Poppins';
    
    --border-1: 5px;

    --shadow-1: 0px 0px 25px 0px #1628361e;

    --left-nav-width: 250px;
}
