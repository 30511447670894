@import "Variables.css";
  
.controls-container {
    --highlight-width: auto;
    --highlight-x-pos: 0;

    display: flex;
}

.controls {
    display: inline-flex;
    justify-content: space-between;
    border: 1.5px solid var(--darker-color);
    border-radius: 30px; /* 5px */
    max-width: 500px;
    font-size: 12px;
    padding: 3px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.controls.segsml {
    padding: 0 3px;
}

.controls.segsml .segment label {
    padding: 5.5px 10px 4px;
}

.controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.segment {
    /* width: 100%; uncomment for each segment to have matching width */
    /* min-width: 70px; */
    position: relative;
    text-align: center;
    z-index: 1;
}

.segment label {
    cursor: pointer;
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6.5px 10px 5px;
    transition: color 0.5s ease;
}

.segment.active label {
    color: var(--light-nav-color);
}

.controls::before {
    content: "";
    background: var(--nav-color);
    border-radius: 30px; /* 4px */
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
}
