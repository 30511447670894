/* autocomplete stuff */
@import "Variables.css";

input.autocomplete {
    margin-bottom: 5px !important;
}

.no-suggestions {
    display: grid;
    grid-template-columns: 1fr auto;
    align-content: space-between;
    color: var(--dark-primary-color);
    padding: 6px 6px 6px 10px;
    border-radius: 6px;
    background: var(--light-primary-color);
}

.no-suggestions button {
    background: none;
    color: var(--dark-primary-color);
    border-color: var(--dark-primary-color);
}

.suggestions {
    background: white;
    border: 1px solid rgb(212, 212, 212);
    list-style: none;
    padding: 5px;
    /* margin-top: -5px; */
    max-height: 143px;
    overflow-y: auto;
    width: 100%;
    border-radius: 10px;
    position: absolute;
    z-index: 1000;
    box-shadow: var(--shadow-1);
}

.suggestions li {
    padding: 5px 10px;
    margin-bottom: 5px;
}

.suggestions li:last-of-type {
    margin-bottom: 0;
}

.suggestions li:hover {
    background-color: var(--light-nav-color);
    color: var(--nav-color);
}

.suggestion-active {
    background-color: var(--nav-color);
    color: var(--light-nav-color);
}

.suggestion-active,
.suggestions li:hover {
    cursor: pointer;
    border-radius: 8px;
}



/* .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
} */

.suggestions li span {
    display: block;
}

.suggestions li span.subtext {
    font-size: 0.8rem;
    color: var(--dark-color);
    line-height: 0.85rem;
    margin-bottom: 5px;
}